import React from 'react'
import { Link } from 'gatsby'
import Layout from 'layouts/default'

export default () => (
  <Layout meta={{ title: '404: Not found' }}>
    <h1>NOT FOUND</h1>
    <p>This page has fallen off the edge of the Internet.</p>
    <p>
      Some call this a "404" error, but I call it unfortunate.
      <br />
      Maybe <Link to="/">visit the home page and try again</Link>?
    </p>
  </Layout>
)
